<template>
	<v-container fluid tag="section">
	 	<v-row align-content="center">
      <v-col cols="12" class="pb-1 d-flex align-center">
        <span class="headline text-capitalize font-weight-bold primary--text" v-text="$route.name"></span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
          <v-card outlined elevation="1" class="mt-2">
				    <v-tabs :vertical="$vuetify.breakpoint.mdAndUp" slider-size="0" class="tabs__settings" active-class="active">
				      <v-tab class="justify-start">
				        <v-icon left>mdi-account-cog</v-icon>
				        Perfil
				      </v-tab>
				      <v-tab class="justify-start" @click="verificateSettings">
				        <v-icon left>mdi-web</v-icon>
				        Mi Sitio
				      </v-tab>

				      <v-tab-item>
				      	<v-sheet class="" v-if="loaders_settings">
			            <v-responsive max-width="900" class="mx-auto">
			              <v-skeleton-loader
			                ref="skeleton"
			                type="article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
			                class="mx-auto"
			              ></v-skeleton-loader>
			            </v-responsive>
			          </v-sheet>
				        <v-card flat class="mt-0 pt-4" :class="{'px-10':$vuetify.breakpoint.mdAndUp, 'px-2':$vuetify.breakpoint.smAndDown }" v-else>
				        	<v-card-title class="pl-0">
				        		Información General
				        		<v-spacer />
	                  <v-btn :loading="restaurant_saving" depressed class="mr-0 font-weight-bold" rounded color="info" small style="padding-left: 20px !important; padding-right: 20px !important" @click="validateRestaurant" v-if="$vuetify.breakpoint.mdAndUp">
				              <v-icon small left>mdi-content-save-all-outline</v-icon> <span v-text="'Guardar'" />
				            </v-btn>
				        	</v-card-title>
				          <v-card-text>
				          	<validation-observer ref="restaurant_account">
				              <v-row>
				                <v-col cols="12" class="pt-0 px-0">
				                  <v-subheader class="px-0">Datos Principales</v-subheader>
				                  <v-divider />
				                </v-col>
				                <v-col cols="12" sm="6" md="6" lg="6" class="py-0">
				                	<span class="label__form primary--text text--lighten-5" v-text="'Nombre del Restaurante'"></span>
				                  <validation-provider name="Nombre del Restaurante" vid="name" rules="required" v-slot="{ errors }">
				                    <v-text-field dense v-model="restaurant.name" outlined name="Nombre del Restaurante" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="restaurant_saving"/>
				                    </v-text-field>
				                  </validation-provider>
				                </v-col>
				                <v-col cols="12" sm="6" md="6" lg="6" class="py-0">
				                	<span class="label__form primary--text text--lighten-5" v-text="'Nombre Fiscal'"></span>
				                  <validation-provider name="Nombre Fiscal" vid="fiscal_name" rules="required" v-slot="{ errors }">
				                    <v-text-field dense v-model="restaurant.fiscal_name" outlined name="Nombre Fiscal" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="restaurant_saving"/>
				                    </v-text-field>
				                  </validation-provider>
				                </v-col>
				                <v-col cols="12" sm="6" md="6" lg="6" class="py-0">
				                	<span class="label__form primary--text text--lighten-5" v-text="'Email'"></span>
				                  <validation-provider name="Email" vid="email" rules="required|email" v-slot="{ errors }">
				                    <v-text-field dense v-model="restaurant.email" outlined name="email" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="restaurant_saving"/>
				                    </v-text-field>
				                  </validation-provider>
				                </v-col>
				                <v-col cols="12" sm="6" md="6" lg="6" class="py-0">
				                	<span class="label__form primary--text text--lighten-5" v-text="'Teléfono'"></span>
				                  <validation-provider name="Telefono" vid="phone_number" rules="required|numeric" v-slot="{ errors }">
				                    <v-text-field dense v-model="restaurant.phone_number" outlined name="Telefono" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="restaurant_saving"/>
				                    </v-text-field>
				                  </validation-provider>
				                </v-col>
				                <v-col cols="12" sm="6" md="6" lg="6" class="py-0">
				                	<span class="label__form primary--text text--lighten-5" v-text="'CIF'"></span>
				                  <validation-provider name="CIF" vid="cif" rules="required" v-slot="{ errors }">
				                    <v-text-field dense v-model="restaurant.cif" outlined name="CIF" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="restaurant_saving"/>
				                    </v-text-field>
				                  </validation-provider>
				                </v-col>
                        <v-col cols="12" sm="6" md="6" lg="6" class="py-0">
				                	<span class="label__form primary--text text--lighten-5" v-text="'Descripción'"></span>
				                  <validation-provider name="Descripción" vid="descripcion" rules="required" v-slot="{ errors }">
				                    <v-text-field dense v-model="restaurant.description" outlined name="descripcion" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="restaurant_saving"/>
				                    </v-text-field>
				                  </validation-provider>
				                </v-col>
				                <v-col cols="12" sm="6" md="6" lg="6" class="py-0"></v-col>
				                <v-col cols="12" class="pt-0 px-0">
				                  <v-subheader class="px-0">Ubicación</v-subheader>
				                  <v-divider />
				                </v-col>
				                <v-col cols="12" class="pa-0">
				                	<validation-provider name="Marcar en el mapa" vid="lat" rules="" v-slot="{ errors }">
				                		<v-text-field dense v-model="restaurant.lat" class="d-none"/>
				                 		<google-map v-model="locationMap"
				                 			@change="setAdrres"
				                 			:error="errors[0]"
				                 			:lat="position.lat"
				                 			:lng="position.lng"
				                 		/>
				                 	</validation-provider>
												</v-col>
				                <v-col cols="12" sm="6" md="6" lg="6" class="py-0 pl-0">
				                	<span class="label__form primary--text text--lighten-5" v-text="'País'"></span>
				                  <validation-provider name="Páis" vid="country" rules="required|alpha_spaces" v-slot="{ errors }">
				                    <v-text-field dense v-model="restaurant.country" outlined name="Pais" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="restaurant_saving"/>
				                    </v-text-field>
				                  </validation-provider>
				                </v-col>
				                <v-col cols="12" sm="6" md="6" lg="6" class="py-0 pr-0">
				                	<span class="label__form primary--text text--lighten-5" v-text="'Ciudad'"></span>
				                  <validation-provider name="Ciudad" vid="city" rules="required|alpha_spaces" v-slot="{ errors }">
				                    <v-text-field dense v-model="restaurant.city" outlined name="Ciudad" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="restaurant_saving"/>
				                    </v-text-field>
				                  </validation-provider>
				                </v-col>
				                <v-col cols="12" sm="6" md="6" lg="6" class="py-0 pl-0">
				                	<span class="label__form primary--text text--lighten-5" v-text="'Provincia'"></span>
				                  <validation-provider name="Provincia" vid="province" rules="required" v-slot="{ errors }">
				                    <v-text-field dense v-model="restaurant.province" outlined name="Provincia" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="restaurant_saving"/>
				                    </v-text-field>
				                  </validation-provider>
				                </v-col>
				                <v-col cols="12" sm="6" md="6" lg="6" class="py-0 pr-0">
				                	<span class="label__form primary--text text--lighten-5" v-text="'Código Postal'"></span>
													<validation-provider name="Código Postal" vid="city" rules="required|numeric" v-slot="{ errors }">
														<v-text-field dense v-model="restaurant.cod_postal" outlined name="Codigo" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="restaurant_saving"/>
														</v-text-field>
													</validation-provider>
												</v-col>
				                <v-col cols="12" class="pa-0">
				                	<span class="label__form primary--text text--lighten-5" v-text="'Dirección'"></span>
				                  <validation-provider name="Direccion" vid="address" rules="required" v-slot="{ errors }">
				                     <v-textarea
                                name="Dirección"
                                auto-grow
                                :error-messages="errors[0]"
                                prepend-inner-icon="mdi-playlist-edit"
                                outlined
                                placeholder=" "
                                v-model="restaurant.address"
                                :disabled="restaurant_saving"
                              ></v-textarea>
				                  </validation-provider>
				                </v-col>
                        <v-col cols="12" sm="4" md="4" lg="4" class="py-0 pr-0">
				                	<span class="label__form primary--text text--lighten-5" v-text="'Facebook'"></span>
													<validation-provider name="Facebook" vid="city" rules="url" v-slot="{ errors }">
														<v-text-field dense v-model="restaurant.facebook" outlined name="Facebook" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="restaurant_saving"/>
													</validation-provider>
												</v-col>
                        <v-col cols="12" sm="4" md="4" lg="4" class="py-0 pr-0">
				                	<span class="label__form primary--text text--lighten-5" v-text="'Instagram'"></span>
													<validation-provider name="Instagram" vid="city" rules="url" v-slot="{ errors }">
														<v-text-field dense v-model="restaurant.instagram" outlined name="Intagram" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="restaurant_saving"/>
													</validation-provider>
												</v-col>
                        <v-col cols="12" sm="4" md="4" lg="4" class="py-0 pr-0">
				                	<span class="label__form primary--text text--lighten-5" v-text="'Whatsapp'"></span>
													<validation-provider name="Whatsapp" vid="city" v-slot="{ errors }">
														<v-text-field dense v-model="restaurant.whatsapp" outlined name="Whatsapp" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="restaurant_saving"/>
														</v-text-field>
													</validation-provider>
												</v-col>
				              </v-row>
				            </validation-observer>
				            <v-row justify="center" v-if="$vuetify.breakpoint.smAndDown">
				            	<v-col cols="8">
				            		<v-btn block :loading="restaurant_saving" depressed class="mr-0 font-weight-bold" rounded color="info" small style="padding-left: 20px !important; padding-right: 20px !important" @click="validateRestaurant">
						              <v-icon small left>mdi-content-save-all-outline</v-icon> <span v-text="'Guardar'" />
						            </v-btn>
				            	</v-col>
				            </v-row>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>
				      <v-tab-item>
<!-- 				      	<v-sheet class="" v-if="loaders">
			            <v-responsive max-width="900" class="mx-auto">
			              <v-skeleton-loader
			                ref="skeleton"
			                type="article, list-item-two-line"
			                class="mx-auto"
			              ></v-skeleton-loader>
			            </v-responsive>
			          </v-sheet>   -->
			            <v-overlay absolute :value="loaders" :opacity="1" :z-index="10" color="white" class="text-center">
							      <v-img
							        contain
							        max-width="120"
							        :src="require('@/assets/Todo-carta-logo.png')"
							      ></v-img>
							      <v-progress-linear
							          color="blue-grey lighten-3"
							          indeterminate
							          rounded
							          height="4"
							        ></v-progress-linear>
							      <h5 class="blue-grey--text mt-4">Obteniendo datos..</h5>
							    </v-overlay>
				        <v-card flat class="mt-0 pt-4" :class="{'px-10':$vuetify.breakpoint.mdAndUp, 'px-2':$vuetify.breakpoint.smAndDown }">
				        	<v-card-title class="pl-0">
				        		Diseño del Sitio
				        		<v-spacer />
	                  <v-btn :loading="settings_saving" depressed class="mr-0 font-weight-bold" rounded color="info" small style="padding-left: 20px !important; padding-right: 20px !important" @click="validateSettins" v-if="$vuetify.breakpoint.mdAndUp">
				              <v-icon small left>mdi-check</v-icon> <span v-text="'Aplicar'" />
				            </v-btn>
				        	</v-card-title>
				          <v-card-text>
				          	<validation-observer ref="restaurant_settings">
				              <v-row>
				                <v-col cols="12" class="pl-0">
				                	<span class="label__form primary--text text--lighten-5" v-text="'Nombre de la Url'"></span>
				                  <validation-provider name="Nombre de la Url" vid="url" rules="required|alpha_dash" v-slot="{ errors }">
				                    <v-text-field dense v-model="settings.url" outlined name="Nombre de la Url" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="settings_saving" :hint="base_url+'/r/'+settings.url" persistent-hint/>
				                    </v-text-field>
				                  </validation-provider>
				                </v-col>
				                 <v-col cols="12" class="pl-0">
				                	<span class="label__form primary--text text--lighten-5" v-text="'Url de Alérgenos'"></span>
				                  <validation-provider name="Url de Alérgenos" vid="url_allergen" rules="url" v-slot="{ errors }">
				                    <v-text-field dense v-model="settings.url_allergen" outlined name="Url de Alergenos" prepend-inner-icon="mdi-playlist-edit" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="settings_saving" />
				                    </v-text-field>
				                  </validation-provider>
				                </v-col>
				                <v-col cols="7" sm="7" md="4" lg="4" class="pl-0 py-0">
				                	<span class="label__form primary--text text--lighten-5" v-text="'Color principal'"></span>
				                  <validation-provider name="Color" vid="colors" rules="required" v-slot="{ errors }">
				                    <v-text-field dense v-model="settings.colors" outlined name="Nombre de la Url" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="settings_saving">
				                    	<template v-slot:prepend-inner>
				                    		<v-icon :style="styleColor">mdi-brightness-1</v-icon>
				                    	</template>
				                    </v-text-field>
				                  </validation-provider>
				                </v-col>
				                <v-col cols="5" sm="5" md="4" lg="4" class="pl-0">
				                 	<v-menu offset-y :close-on-content-click="false">
				                      	<template v-slot:activator="{ on }">
				                        	<v-btn depressed outlined class="mt-4" v-on="on" style="padding-left: 10px !important; padding-right: 10px !important; !important; height: 39px;">
				                          		<v-icon :style="styleColor" large>mdi-palette</v-icon>
				                          		<v-icon small>mdi-chevron-down</v-icon>
				                        	</v-btn>
				                      	</template>
																<v-color-picker hide-inputs show-swatches v-model="settings.colors"></v-color-picker>
			                    	</v-menu>
				                </v-col>
                        <v-col cols="5" sm="5" md="4" lg="4" class="pl-0" v-if="this.$store.getters['auth/isAdmin']">
				                 	<span class="label__form primary--text text--lighten-5" v-text="'Seleccione monedas:'"></span>
				                  <validation-provider name="monedas" vid="monedas" rules="required" v-slot="{ errors }">
				                    <v-select
                              v-model="settings.coins"
                              :items="coins"
                              :menu-props="{ maxHeight: '400' }"
                              label="Seleccione"
                              multiple
                              item-text="coin"
                              item-value="cod"
                              :error-messages="errors[0]"
                              hint="Seleccione las monedas"
                              persistent-hint
                            ></v-select>
				                  </validation-provider>
				                </v-col>
				              </v-row>
				              <v-row>
		              	    <v-col cols="12" sm="6" md="5" lg="5" class="pb-0 pl-0" >
		              	    	<span class="label__form primary--text text--lighten-5" v-text="'Logotipo'"></span>
										        <v-card flat class="mt-2">
                            <v-card-text class="d-flex align-center justify-center pa-0" style="height:13rem"  v-if="cargaInicialLogo" :class="{'imagezone': !cargaInicialLogo}">
										          <template>
										            <div class="v-responsive v-image grey lighten-2 pa-3" :class="{'d-none': !cargaInicialLogo}" style="height:13rem; width:100%">
										              <div id="imagePreviewlogo" class="v-image__image" :style="imageLogo">
										              </div>
										               <v-overlay absolute :opacity="0.1" :value="true" color="light-blue lighten-5">
										                <v-btn icon x-large color="primary" @click="deleteImage('logo')">
										                  <v-icon size="40">mdi-trash-can-outline</v-icon>
										                </v-btn>
										              </v-overlay>
										            </div>
										          </template>
                            </v-card-text>
                            <v-card-text class="v-responsive v-image grey lighten-2 pa-3" v-else>
                              <croppa
                                :width="400"
                                :height="400"
                                :quality="2"
                                style="margin:auto"
                                placeholder="Elige el logotipo"
                                :placeholder-font-size="18"
                                v-model="logotipo"
                                initial-position="center"
                                :show-remove-button="true"
                                remove-button-color="black"
                              >
                              </croppa>
                            </v-card-text>

										        <!-- <validation-provider name="Imagen principal" vid="image_logo" rules="" v-slot="{ errors }">
											       	<div class="v-messages theme--light error--text"> <span class="v-messages__message" v-text="errors[0]"></span></div>
											      </validation-provider> -->
										      </v-card>
										    </v-col>
										    <v-col cols="12" sm="6" md="7" lg="7" class="pb-0 pl-0" >
		              	    	<span class="label__form primary--text text--lighten-5" v-text="'Imagen portada'"></span>
										      <v-card flat class="mt-2">
										        <v-card-text class="d-flex align-center justify-center pa-0" style="height:13rem" v-if="cargaInicialPortada" :class="{'imagezone': !cargaInicialPortada}" >
										          <template>
										            <div class="v-responsive v-image grey lighten-2 pa-3" :class="{'d-none': settings.image_header.length === 0}" style="height:13rem; width:100%">
										              <div id="imagePreviewheader" class="v-image__image" :style="imageHeader">
										              </div>
										               <v-overlay absolute :opacity="0.1" :value="true" color="light-blue lighten-5">
										                <v-btn icon x-large color="primary" @click="deleteImage('header')">
										                  <v-icon size="40">mdi-trash-can-outline</v-icon>
										                </v-btn>
										              </v-overlay>
										            </div>
										          </template>
										        </v-card-text>
                            <v-card-text flat class="v-responsive v-image grey lighten-2 pa-3" v-else>
                              <croppa
                                accept=".jpeg,.png"
                                style="margin:auto"
                                placeholder="Elige la portada"
                                :placeholder-font-size="18"
                                v-model="logoheader"
                                initial-position="center"
                                :width="400"
                                :height="225"
                                :quality="4.8"
                                :show-remove-button="true"
                                remove-button-color="black"
                              ></croppa>
                            </v-card-text>
										      </v-card>
										    </v-col>
				              </v-row>
				              <v-row justify="center">
				              	<v-col cols="12" sm="7" md="5" lg="5" class="text-center">
				              		<span class="label__form primary--text text--lighten-5 my-3" v-text="'Código QR'"></span>
				              		<qrcode-vue :value="'https://todocartas.online/r/'+settings.url" :size="200" renderAs="svg"></qrcode-vue>
				              		<v-btn small :loading="load_pdf" tile color="info" class="my-3" @click="print"><v-icon left>mdi-adobe-acrobat</v-icon>Descargar PDF</v-btn>
				              	</v-col>
				              </v-row>
				            </validation-observer>
				            <template v-if="$vuetify.breakpoint.smAndDown">
					            <v-row justify="center" v-if="$vuetify.breakpoint.smAndDown">
					            	<v-col cols="12">
					            		<base-divider dense align="left" max-width="100%" space="1" />
					            	</v-col>
					            </v-row>
					            <v-row justify="center">
					            	<v-col cols="8">
					            		<v-btn block :loading="settings_saving" depressed class="mr-0 font-weight-bold" rounded color="info" small style="padding-left: 20px !important; padding-right: 20px !important" @click="validateSettins">
							              <v-icon small left>mdi-check</v-icon> <span v-text="'Aplicar'" />
							            </v-btn>
					            	</v-col>
					            </v-row>
				            </template>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>
				    </v-tabs>
				  </v-card>
      </v-col>
    </v-row>
<!--     <div id="pdf" class="d-none">
	   	<div style="width: 2000px;  height: auto;  background-position: center center;  background-repeat: no-repeat;   background-size: cover;   padding: 24px; margin-top: 100px" :style="{'background': `url(${fondo}) !important`}">
	     <div class="row">
	       <div class="col-6">
	         <div class="justify-center" style="text-align: center; margin-bottom: 20px;">
	           <img :src="url_logo+pdf_logo" alt="" height="150" width="150">
	         </div>
	         <div class="col-inner" style="padding-top: 24px; padding-bottom: 24px; width: 320px; text-align: right;">
	           <img :src="require('@/assets/phone.png')" alt="" height="250" width="140">
	         </div>
	         <div class="col-inner" style="width: 240px; margin-top: 130px; text-align: center;">
	          <span class="open-title">Abre la cámara de tu móvil y escanea</span>
	         </div>
	         <br>
	        <div class="col-inner" style="text-align: center; font-size: 18px;letter-spacing: 0.1rem; width: 700px;">
	          <span style="display: block"><strong>Dirección: </strong> {{ pdf_restaurant.address }}</span> <br>
	        </div>
	         <div class="col-inner" style="text-align: center; font-size: 18px;letter-spacing: 0.1rem; width: 700px">
	          <span><strong>Teléfono: </strong> {{ pdf_restaurant.phone_number }}</span>
	        </div>
	        <div class="col-inner" style="text-align: center; width: 700px; padding-top: 50px">
	          <span class="text-footer">ESTABLECIMIENTO CON CARTAS SIN RIEGO DE CONTAGIO</span>
	        </div>
	       </div>
	       <div class="" style="margin-left: 1rem; width: 700px; float: left; padding: 15px">
	          <div class="col-inner" style="text-align: center; width: 700px; padding-top: 20px">
	            <span class="title-scan"><strong>ESCANEA</strong></span> <br>
	          </div>
	           <div class="col-inner" style="text-align: center;  width: 700px;">
	            <span class="title-scan">LA CARTA</span> <br>
	          </div>
	          <div class="col-inner justify-center" style="text-align: center;  width: 700px; margin-top: 15px ">
	            <div class="box-QR">
	            	<qrcode-vue :value="'https://todocartas.online/restaurante/'+settings.url" :size="340" level="H" renderAs="svg"></qrcode-vue>
	            </div>
	          </div>
	            <div class="col-inner" style="text-align: center;width: 600px; padding-top: 40px; margin-left: 50px">
	               <span class="url" v-text="'todocartas.online/restaurante/'+pdf_url"></span>
	            </div>
	       </div>
	     </div>
	    </div>
    </div> -->

		<div id="pdf" class="d-none">
			<div style="background-position: center center;  background-repeat: no-repeat;   background-size: cover;" :style="{'background': `url(${fondo}) !important`}">
		   	<div style="width: 1320px;height: auto;margin: 25px;border: 1px solid black;padding: 47px 0;">
			    <div class="row">
					<div class="col-7">
					    <div class="justify-center mt-5 text-center" >
					    	<img :src="url_logo+pdf_logo" alt="" height="150">
					    </div>
						<div class="position-relative" style="background-size: contain;height: 339px;margin: 60px 0;" :style="{'background': `url(${phone}) center bottom no-repeat`}">
							<div class="open-title position-absolute">
								<span>Abre la cámara de tu móvil y escanea</span>
							</div>
						</div>
						<div class="justify-center mt-5 text-center">
							<p><strong>Dirección: </strong> {{ pdf_restaurant.address }}</p>
						</div>
						<div class="justify-center mt-5 text-center">
								<p><strong>Teléfono: </strong> {{ pdf_restaurant.phone_number }}</p>
						</div>
					    <div class="justify-center mt-5 text-center">
					      <p class="text-footer">ESTABLECIMIENTO CON CARTAS SIN RIEGO DE CONTAGIO</p>
					    </div>
					</div>
					<div class="col-5">
						<div class="col-inner">
							<p class="title-scan">ESCANEA<br>LA CARTA</p>
						</div>
						<div class="col-inner justify-center" style="text-align: center; ">
							<div class="box-QR">
								<qrcode-vue class="mt-3" :value="'https://todocartas.online/r/'+settings.url" :size="301" level="H" renderAs="svg"></qrcode-vue>
							</div>
						</div>
						<div class="col-inne d-flex align-items-end justify-content-center" style="height:248px">
							<p class="url">todocartas.online/r/<br>{{ pdf_url }}</p>
						</div>
					</div>
			    </div>
		    </div>
	    </div>
	  </div>

    <base-material-snackbar
      v-model="snackbar.active"
      :type="snackbar.type"
      bottom
      right
    >
      {{ snackbar.text }}
    </base-material-snackbar>
	</v-container>
</template>
<script>
import GoogleMap from "../../components/Map";
import QrcodeVue from 'qrcode.vue'
const os = require('os');
	export default{
  		components: { QrcodeVue,
  		 GoogleMap
      },
      name:'Settings',
		data(){
			return {
				restaurant:{
          name: '',
          fiscal_name: '',
          email: '',
          address: '',
          city: '',
          province: '',
          country: '',
          phone_number: '',
          username: '',
          password: '',
          cif: '',
          description:'',
          rol: 'Dueño restaurante',
          restaurant_id: '',
          cod_postal: '',
          lat: '',
          lon: '',
          facebook: '',
          instagram: '',
          whatsapp: ''
        },
        pdf_restaurant: {},
        pdf_url:'',
        pdf_logo:'',
        id:'',
        settings:{
        	url: '',
        	url_allergen: '',
        	colors: '',
        	id:'',
        	image_logo: '',
        	image_header: '',
        	restaurant_id: '',
        	image_delete_logo: false,
        	image_delete_header: false,
          coins: []
        },
        snackbar:{
          text:'',
          active: false,
          type:''
        },
        restaurant_saving: false,
        settings_saving: false,
        loaders_settings: false,
        loaders: false,
        locationMap: {},
        fondo: require('@/assets/background.jpg'),
        phone: require('@/assets/phone.png'),
        url_logo: process.env.VUE_APP_IMG_URL_API + 'images/restaurants/',
        base_url: os.hostname(),
        load_pdf: false,
        logotipo: {},
        logoheader:{},
        cargaInicialLogo:true,
        cargaInicialPortada:true,
        coins:[]
			}
		},
		created(){
			if(this.$store.getters['auth/isAdmin']){
        this.id = this.$route.params ? this.$route.params.id : '';

      }
      else{
        this.id =  this.$store.getters['auth/restaurant'];
      }
      this.getCoins();
			this.getRestaurant();
		},
		methods:{
      async getCoins(){
        const { data } = await this.$api.get('api/restaurants/coins');
        this.coins = data.data.coins;
      },
			async print(){
	      // Pass the element id here
	      // this.$htmlToPaper('pdf');
	      this.load_pdf = true;
	      try{
	      	const { data } = await this.$api.get(`api/restaurants/downloadPdf?restaurant_id=${this.id}`);
	      	var link = document.createElement('a');
					link.download = `${this.settings.url}.pdf`;
					link.href = 'data:application/octet-stream;base64,' + data.pdfBase64;
					link.setAttribute('class', 'd-none');
					document.body.appendChild(link);
					link.click();
          link.remove();
	      }
	      catch(e){
	      	this.snackbar = {
            text:  'Lo sentimos, hubo un error al Generar el PDF.',
            type: 'error',
            active: true
          }
	      }
	      finally{
	      	this.load_pdf = false;
	      }
	    },
			setAdrres(){
				  this.restaurant.address = this.locationMap.address;
          this.restaurant.city = this.locationMap.administrative_area_level_1;
          this.restaurant.province = this.locationMap.locality;
          this.restaurant.country = this.locationMap.country;
          this.restaurant.cod_postal = this.locationMap.postal_code;
          this.restaurant.lat = this.locationMap.lat;
          this.restaurant.lon = this.locationMap.lng;
			},
			async getRestaurant(){
        this.loaders_settings = true;
        const url = `api/restaurants/${this.id}/edit`;
        try{
          const { data } = await this.$api.get(url);
          //console.log(data);
          this.restaurant = {...data.data.restaurant };
          this.pdf_restaurant = {...data.data.restaurant };
        	this.loaders_settings = false;
        }
        catch(e){
          this.snackbar = {
            text:  e.response.data.message,
            type: 'error',
            active: true
          }
        }
      },
      async getSetting(){
        this.loaders = true;
        const url = `api/restaurants/settings?restaurant_id=${this.id}`;
        try{
          const { data } = await this.$api.get(url);
          const color = JSON.parse(data.data.settings.colors);
          this.settings.id = data.data.settings.id;
          this.settings.url = data.data.settings.url;
          this.pdf_url = data.data.settings.url;
          this.settings.url_allergen = data.data.settings.url_allergen === 'null' ? '' : data.data.settings.url_allergen;
          this.settings.image_logo = data.data.settings.image_logo;
          this.pdf_logo = data.data.settings.image_logo;
          this.settings.image_header = data.data.settings.image_header;
          this.settings.colors = color.default;
          console.log('test')
          this.settings.coins = JSON.parse(data.data.settings.coins);
          this.settings.restaurant_id = this.id;
          if(data.data.settings.image_logo != this.$store.getters['auth/logo']){
          	this.$store.commit('auth/SET_LOGO', data.data.settings.image_logo)
          }
        	this.loaders = false;
        }
        catch(e){
          this.snackbar = {
            text:  e.response.data.message,
            type: 'error',
            active: true
          }
        }
      },
      verificateSettings(){
      	if(Object.values(this.settings).every(item => item == ""))
      		this.getSetting();
      },
      validateRestaurant(){
        this.$refs.restaurant_account.validate().then(result =>{
          if(result){
            this.updateRestaurant();
          }
        });
      },
      validateSettins(){
        this.$refs.restaurant_settings.validate().then(result =>{
          if(result){
            this.updateSettings();
          }
        });
      },
      async updateRestaurant(){
        this.restaurant_saving = true;
        try{
          const send_user = await this.$api({
            url: `api/restaurants/${this.id}`,
            method: 'PUT',
            data: this.restaurant
          });
          this.getRestaurant();
          this.restaurant_saving = false;
          this.snackbar = {
            text: send_user.data.message,
            type: 'success',
            active: true
          }
        }
        catch(e){
          if(e.response.status == "422"){
            this.$refs.restaurant_account.setErrors(e.response.data.data);
          }
          else{
            this.snackbar = {
              text:  e.response.data.message,
              type: 'error',
              active: true
            }
          }
          this.restaurant_saving = false;
        }
      },
      async updateSettings(){
        this.settings_saving = true;
        var promesa = [];

        var dataSettings = new FormData;
        dataSettings.append('url', this.settings.url);
        dataSettings.append('url_allergen', this.settings.url_allergen);
        dataSettings.append('colors', this.settings.colors);
        dataSettings.append('id', this.settings.id);
        dataSettings.append('restaurant_id', this.settings.restaurant_id);
        dataSettings.append('image_delete_logo', +this.settings.image_delete_logo);
        dataSettings.append('image_delete_header', +this.settings.image_delete_header);

        for (let i = 0; i < this.settings.coins.length; i++) {
          let coin = this.settings.coins[i];
          for (let index = 0; index < this.coins.length; index++) {
            let coins = this.coins[index];
            if (coins.cod === coin) {
              coin = coins;
            }
            //console.log(coin);
          }


          dataSettings.append('coins[]', JSON.stringify(coin));
        }

        dataSettings.append('image_delete_header', +this.settings.image_delete_header);



        ////console.log(typeof this.logotipo);

        //Comprueba que el objeto de logotipo no este vacio
        if(Object.keys(this.logotipo).length != 0 ){
          //Compruebo si tiene una imagen valida
          if (this.logotipo.hasImage()) {
            promesa.push(['image_logo',this.logotipo.promisedBlob('image/jpeg',1)]);
          }
        }

        //Comprueba que el objeto de la portada no este vacio
        if(Object.keys(this.logoheader).length != 0 ){
          //Compruebo si tiene una imagen valida
          if (this.logoheader.hasImage()) {
            promesa.push(['image_header',this.logoheader.promisedBlob('image/jpeg',1)]);
          }
        }

        //comprobamos si las imagenes tienen promesas y segun sea el caso
        //los valores son 1 para logo, 2 para portada y 3 para logo y portada.

        if (promesa.length === 0) {
          try{
            const send_user = await this.$api({
              url: 'api/restaurants/settings',
              method: 'POST',
              data: dataSettings
            });
            this.getSetting();
            this.settings_saving = false;
            this.snackbar = {
              text: send_user.data.message,
              type: 'success',
              active: true
            }
          }
          catch(e){
            if(e.response.status == "422"){
              this.$refs.restaurant_settings.setErrors(e.response.data.data);
            }
            else{
              this.snackbar = {
                text:  e.response.data.message,
                type: 'error',
                active: true
              }
            }
            this.settings_saving = false;
          }
        } else {
          for (let $i=0;$i<promesa.length;$i++) {
            let item = promesa[$i];
            item[1].then(res=>{
               dataSettings.append(item[0], res, 'filename.jpeg')
            }).then(res =>{
              try {
                const send_user = this.$api({
                  url: 'api/restaurants/settings',
                  method: 'POST',
                  data: dataSettings
                });
                send_user.then(res =>{
                  this.getSetting();
                  this.settings_saving = false;
                  this.snackbar = {
                    text: res.data.message,
                    type: 'success',
                    active: true
                  }
                })
              } catch(e){
                if(e.response.status == "422"){
                  this.$refs.restaurant_settings.setErrors(e.response.data.data);
                }
                else{
                  this.snackbar = {
                    text:  e.response.data.message,
                    type: 'error',
                    active: true
                  }
                }
                this.settings_saving = false;
              }

            })
          }
        }
        this.settings_saving = false;
      },
      renderImage(file, type){
        const image = file.target.files[0] || file.dataTransfer.files
        if(image.length === 0) return;
        this.settings[`image_${type}`] = image;
        this.settings[`image_delete_${type}`] = false;

        const preview = document.querySelector('#imagePreview'+type);

        let reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onload = () => {
          if (image.type.match('image/png') || image.type.match('image/jpeg') || image.type.match('image/gif')) {
            preview.style.backgroundImage = `url("${ reader.result }")`;
            preview.style.backgroundSize = 'contain';
            preview.style.backgroundPosition = 'center center';
          }
        };
      },
      deleteImage(type){
        this.settings[`image_${type}`] = '';
        this.settings[`image_delete_${type}`] = true;
        const preview = document.querySelector('#imagePreview'+type);
        preview.style.backgroundImage = 'none';
        if (type==='logo') {
          this.cargaInicialLogo = false;
        }else{
          this.cargaInicialPortada = false;
        }
      }
		},
		computed:{
			styleColor(){
				return {
					color: this.settings.colors ? this.settings.colors : '#283145',
					'margin-top': '1px'
				}
			},
			imageLogo(){
				return {
					'background-image': typeof this.settings.image_logo !== 'object' ? `url("${process.env.VUE_APP_IMG_URL_API}images/restaurants/${this.settings.image_logo }")` : '',
					'background-size': 'contain',
					'background-position': 'center'
				}
			},
			imageHeader(){
				return {
					'background-image': typeof this.settings.image_header !== 'object' ? `url("${process.env.VUE_APP_IMG_URL_API}images/restaurants/${this.settings.image_header }")` : '',
					'background-size': 'contain',
					'background-position': 'center'
				}
			},
			position(){
				return {
					lat: parseFloat(this.restaurant.lat),
					lng: parseFloat(this.restaurant.lon),
				}
			}
    }

	}
</script>
