<template>
  <v-row>
     <v-col cols="12" class="pb-0">
      <span class="label__form primary--text text--lighten-5" v-text="'Márque la dirección exacta o cercana de su restaurante en el mapa'"></span>
     </v-col>
    <v-col cols="12" class="d-inline-flex pb-0">
      <div class="v-input py-2 v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined" :class="{'error--text': error, 'v-input--has-state': error}">
        <div class="v-input__control">
          <div class="v-input__slot">
            <fieldset aria-hidden="true"><legend style="width: 0px;"><span/></legend></fieldset>
            <div class="v-text-field__slot">
              <gmap-autocomplete placeholder="Introduce la ubicación de tu restaurante" @place_changed="setPlace">
              </gmap-autocomplete>
            </div>
          </div>
        </div>
      </div>
      <v-btn color="info" tile depressed  @click="addMarker" class="ml-3 mt-1">
        <v-icon left>mdi-map-search</v-icon> Marcar
      </v-btn>
    </v-col>
    <div class="v-messages theme--light error--text pl-4" v-if="error"> <span class="v-messages__message" v-text="error"></span></div>
    <v-col cols="12">
      <gmap-map
        :center="center"
        :zoom="8"
        style="width:100%;  height: 400px;"
      >
        <gmap-marker
          :position="markers.position"
          @click="center=markers.position"
        ></gmap-marker>
      </gmap-map>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "GoogleMap",
  props: {
      error: {
        type: String,
      },
      lat: {
        type: Number,
        default: 0,
      },
      lng: {
        type: Number,
        default: 0,
      },
    },
  data() {
    return {
      // default to Montreal to keep it simple
      // change this to whatever makes sense
      center: { lat: 45.508, lng: -73.587 },
      markers: '',
      places:
      {
        neighborhood:'',
        locality: '',
        administrative_area_level_1: '',
        country: '',
        postal_code: '',
        lat: '',
        lng: '',
        address: '',
      },
      currentPlace: null
    };
  },

  mounted() {
    if(this.lat != '' && this.lng != ''){
      this.center = {
        lat: this.lat,
        lng: this.lng
      };
      this.markers = { position: this.center };
    }
    else
      this.geolocate();
  },

  methods: {
    getPosition(e){
      this.markers = { position: e.latLng };
      this.places.lat = e.latLng.lat();
      this.places.lng = e.latLng.lng();
      this.$emit('input', {...this.places});
      this.$emit('change');
    },
    // receives a place object via the autocomplete component
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng()
        };
        this.markers = { position: marker };
        this.getLocalition(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },
    getLocalition(place){
       var componentForm = {
        neighborhood: 'short_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name'
      };

      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (componentForm[addressType]) {
          var val = place.address_components[i][componentForm[addressType]];
          this.places[addressType] = val;
        }
      }

      this.places.address = place.formatted_address;
      this.places.lat = place.geometry.location.lat();
      this.places.lng = place.geometry.location.lng();
      this.$emit('input', {...this.places});
      this.$emit('change');
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
    }
  },
  filters:{
    json(val){
      return JSON.stringify(val)
    }
  }
};
</script>
